
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: PTSansRegular,Arial,Helvetica,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0px;
  margin-top: 1px !important;
  margin-bottom: 1px !important;
  margin-left: 1px !important;
  margin-right: 1px !important;
  border: 0px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.flex {
  display: flex;
}

/* .contaminantListContainer {
  margin: 50px;
} */

/********************************************************************************************/
/* Start Footer Styles*/
.footerLinks {
    background-image: url("../images/Metal_Bottom.jpg");
    background-color: rgba(255,255,255,0);
    background-position: center center;
    background-repeat: no-repeat;
    border-width: 0px 0px 0px 0px;
    border-color: #5b5d62;
    border-style: solid;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 89px ;
    padding-top: 19px;
}

.footerLinksContainer {
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100vw;
  justify-content: space-around;
  align-items: center;
}

.footerLinks .footerLink a {
  color: #5b5d62;
  font-size: 1.6rem;
  font-weight: 700;
  text-decoration: none;
}

.footerLinks .footerLink a:link {
  color: #5b5d62;
}

.footerLinks .footerLink a:visited {
  color: #5b5d62;
}

.footerLinks .footerLink a:hover {
  color: #000000;
}

.footerLinks .footerLink a:active {
  color: #5b5d62;
}

.footer .lowesLogo {
  background-image: url("../images/Footer_Background.jpg");
  background-size: 100% 100%;
  min-height: 382px;
  min-width: 1080px;
  background-repeat: no-repeat;
  position: relative;
}

.footer .nowWhatBox {
  position: absolute;
  top: 58%;;
  left: 60%;
}

.footer .nowWhatImage {
  width: 50%;
}

/* End Footer Styles*/

/********************************************************************************************/
/*Start Header Styles*/

.header .headerWithLogo {
  width: 100%;
}

.whatsInWater {
  margin: 0px;
  font-size: 4rem;
  color: rgb(23, 155, 186);
  line-height: 1.2;
}

.textBold {
  font-weight: 500;
}

.blockElem {
  display: block;
}

.nopadding {
  padding: 0px !important;
  margin: 0px !important;
}

/* .spinner{
  position: absolute;
  height: 50px !important;
  width: 500px!important;
  top: 50% !important;
  left: 50% !important;
  margin-left: -450px !important;
  margin-top: 120px !important ;
  background: url("../images/spin-loader.gif");
  background-size: 100%; 
} */

.waterProgressBar {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 50%;
  left: 25%;
}

.waterDropletProgress {
  position: absolute;
  top: 50%;
  left: 40%;
}

.spinnerContainer{
  position: relative;
  width: 100%;
}

.modal-body {
  height: 100px;
}

.modal {
  background-color: rgba(255,255,255,0.8);
}

/*end Header Styles*/

/********************************************************************************************/
/*start Search ZipCode Styles*/
 
.searchZipCodeContainer .zipInput, .facilitiesListContainer .zipInput {
  border: none;
  border-bottom: 1px solid #c1c3c4;
  font-size: 2em;
  padding-top: 10px;
  font-family: Lato,sans-serif;
  font-weight: 300;
  text-indent: 8px;
  background: 0 0;
  min-width: 18%;

}

.searchZipCodeContainer {
  width: 100%;
  text-align: center;
  vertical-align: middle;
  min-height: 776px;
}

.searchPageSpacer {
  height: 300px;
}

.searchZipCodeContainer .infoText {
  font-size: 1.3rem;
}

.searchZipCodeContainer .goButton, .facilitiesListContainer .goButton {
  background-color: #e15f2e;
  border: 2px solid;
  color: #FFFFFF;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-width: 2px;
  border-radius: 50px;
}

.searchZipCodeContainer .goButton:hover, .facilitiesListContainer .goButton:hover {
  background-color: #FFFFFF;
  border: 2px solid;
  color: #e15f2e;
}


/*end Search ZipCode Styles*/

/********************************************************************************************/
/* start facilitiesList styles*/
.facilitiesListContainer {
  width: 100%;
  min-height: 1060px;
  position: relative;
}

.facilitiesListContainer .facilitiesListInnerContainer {
  width: 90%;
  position: absolute;
  left: 5%;
}

.facilitiesListContainer .facilitiesTableContainer {
  flex-direction: row;
  justify-content: center; 
  width: 100%;
  
}

.facilitiesListContainer .search-results-table {
  width: 100%;
}

.facilitiesListContainer .search-results-table thead tr{
  border-bottom: 3px solid rgb(147, 149, 152);
  height: 50px;
}

.facilitiesListContainer .search-results-table tbody td{
  border-bottom: 1px solid rgb(147, 149, 152);
  height: 50px;
}

.facilitiesListContainer .blueText1{
  font-size: 1.2rem;
  color: #1f497f;
}

.facilitiesListContainer .blueText2{
  color: #1f497f;
}

.facilitiesListContainer .primary-btn {
  background-color: #ec602a;
  color: #fff;
  border-radius: 30px;
  display: inline-block;
  padding: 14px 40px;
  cursor: pointer;
  font-weight: 700;
}

/* end facilitiesList styles*/


/********************************************************************************************/
/*start contaminantsList Styles*/

.contaminantListContainer {
  width: 100%;
  vertical-align: middle;
  min-height: 400px;
}

/* .contaminantListContainer .contaminantsGuideLines {
  padding-left: 20rem;
  padding-right: 20rem;
} */

.contaminantListContainer .contaminantsCircle {
    background-position: left top;
    background-repeat: no-repeat;
    background-size: cover;
    border-width: 6px;
    border-color: rgb(225, 95, 46);
    border-style: solid;
    border-radius: 250px;
    overflow: hidden;
    padding: 1% 30% 1%;
    min-height: 0px;
}

.contaminantListContainer .contaminantsDetected {
  margin-top: -20px;
}


.contaminantListContainer .totalContaminants {
  width: 100%;
}

.contaminantListContainer .contaminantsLegal {
  padding-left: 60px;
}

.contaminantListContainer .blueHeader2 {
    font-size: 2rem;
    color: #179bba;
    padding-bottom: 25px;
    transform: translate3d(0,0,0)
}

.contaminantListContainer .blueHeader1 {
  font-size: 2.5rem;
  color: #179bba;
  padding-bottom: 25px;
  transform: translate3d(0,0,0)
}

.contaminantListContainer .blueText1 {
  font-size: 1.3rem;
  color: #179bba;
}

.contaminantListContainer .blueText2 {
  font-size: 8rem;
  color: #179bba;
}

.contaminantListContainer .infoText {
  font-size: 1.8rem;
}

.contaminantListContainer .infoText2 {
  font-size: 1.5rem;
}

.contaminantListContainer .getYourWaterTestedImg {
  width: 200px;
  height: 85px;
}

.contaminantListContainer .danger {
  color:rgb(255, 0, 0);
}

.contaminantListContainer .contaminantTypeLabel {
  padding: 5px 5px;
  color: #fff;
  font-size: 20px;
}

.contaminantListContainer .contaminantType {
  margin-top: 1em;
  margin-bottom: 1em;
  background-color: #3e9cda;
  width: 100%;
}
.contaminantListContainer .contaminant-grid-item {
  -webkit-box-shadow: 0 -4px 10px rgba(0,0,0,.14);
  box-shadow: 0 -4px 10px rgba(0,0,0,.14);
  color: #1f497f;
  padding: 20px;
}

.contaminantListContainer .contaminant-grid-item .contaminant-name {
  text-align: center;
}

.contaminantListContainer .contaminant-grid-item.card-highLight {
  background-color: #dde8f7;
}

.contaminantListContainer .contaminant-grid-item .contaminant-name h3 {
  color: #1f497f;
  display: initial;
  font-size: 18px;
  font-weight: 700;
  margin: 0 0 8px;
}

.contaminantListContainer .contaminant-grid-item .potentital-effect {
  color: #1f497f;
  font-size: 14px;
  margin: 0 0 26px;
}

.contaminantListContainer .contaminant-grid-item .detect-times-greater-than {
  color: #1f497f;
  font-size: 70px;
}
.contaminantListContainer .contaminant-grid-item .btn-contam-details {
    background-color: #3e9cda;
    border: 3px solid transparent;
    border-radius: 24px;
    color: #fff;
    cursor: pointer;
    margin: 30px 0 0;
    padding: 7px 25px;
}

.contaminantListContainer .contaminant-grid-item .btn-contam-details:hover {
  background-color: #fff;
  color: #3e9cda;
  border: 3px solid #3e9cda;
}

.contaminantListContainer .contaminant-grid-item .btn-contam-wrapper {
  padding-top: 20px;
  text-align: center;
}

.contaminantListContainer .contaminant-grid-item .detect-levels-item {
  display: flex;                  
  flex-direction: row;            
  flex-wrap: nowrap;              
  justify-content: space-between; 
}

.contaminantListContainer .contaminant-grid-item .detect-levels-item-key {
  font-size: 12px;
  font-weight: bold;
}

.contaminantListContainer .contaminant-grid-item .detect-levels-item-value {
  font-size: 12px;
  font-weight: bold;
}

.contaminantListContainer .filter-table {
  width: 100%;
}

.contaminantListContainer .filter-table thead img {
  width: 80%;
  height: 80%;
}

.contaminantListContainer .filter-table .table-first-col {
  width: 50%;
  padding: 6px 10px;
}

.contaminantListContainer .filter-table-wrapper .filter-table-subheader {
  background-color: #404041;
  color: #fff;
  letter-spacing: .1em;
  line-height: 1.3;
}

.contaminantListContainer .filter-table-wrapper .filter-table-subheader td {
  padding: 6px 10px;
}

.contaminantListContainer .filter-table-wrapper tbody .filterTableCell {
  font-weight: bold;
  text-align: center;
}

.contaminantListContainer .filter-table-wrapper tbody .filterTableCell span {
  margin-left: -30px;
}
/*end contaminantsList Styles*/

/* start Details CSS */
/********************************************************************************************/

.contam-modal-wrapper {
  background-color: rgba(25,66,109,.8);
  bottom: 0;
  left: 0;
  padding: 40px 0 0;
  position: fixed;
  right: 0;
  top: 10px;
  z-index: 9;
}

.contam-modal-wrapper .contam-modal-header-wrapper {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 0 30px;
}

.contam-modal-wrapper .contam-modal-details {
  padding: 54px;
}

.contam-modal-wrapper .contam-modal-details {
  background-color: #fff;
  border: 5px solid #3e9cda;
  bottom: 50px;
  left: 0;
  margin: 0 auto;
  max-height: 100%;
  max-width: 1000px;
  overflow: auto;
  padding: 20px;
  position: absolute;
  right: 0;
  top: 50px;
}

.contam-modal-wrapper .contam-modal-details .btn-contam-details {
  background-color: #3e9cda;
  border: 3px solid transparent;
  border-radius: 24px;
  color: #fff;
  cursor: pointer;
  margin: 30px 0 0;
  padding: 7px 25px;
}

.contam-modal-wrapper .contam-modal-details .close-modal {
  color: #000;
  font-size: 18px;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 30;
}

.contam-modal-details h2 {
  font-size: 24px;
  margin-left: 0;
  font-weight: 600;
}

.contam-modal-wrapper .contam-modal-details .ewg-health-guideline-chart {
  background-color: #009a4f;
}

.contam-modal-wrapper .contam-modal-details .contam-chart-bar {
  background-color: #e0e1e2;
  height: 32px;
  margin: 0 10px 0 0;
}

.contam-modal-wrapper .contam-modal-details .contam-bar-wrapper {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 0 14px;
}

.contam-modal-wrapper .contam-modal-details .this-utility-chart {
  background-color: #3e9cda;
}

.contam-modal-wrapper .contam-modal-details .mcl-chart-no-limit {
  background-color: #19426d;
}

.ewg-health-guideline-chart-no-limit, .mcl-chart-no-limit {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  padding: 0 8px;
  width: 100%;
}

.contaminant-chart p {
  color: #1f497f !important;
  font-weight: 600;
  margin: 0 0 0 10px;
}

.contam-modal-details p {
  color: #767676;
  font-size: 18px;
  margin-left: 0;
}

.contam-modal-details h2 {
  font-size: 24px;
  margin-left: 0;
  font-weight: 600;
  margin-top: 40px;
}

.health-risks h2 {
  margin-bottom: 16px;
  margin-top: 40px;
  color: #48a2dc;
}

.pollution-filtering-wrapper .pollution-source-wrapper p {
  max-width: 125px;
  }

.contam-modal-wrapper .contam-modal-details .levels-compare-h2,
.contam-modal-wrapper .contam-modal-details .pollution-filtering-wrapper h2 {
  color: #48a2dc;
}

.contam-modal-wrapper .contam-modal-details .pollution-filtering-wrapper {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: space-around;
}

.contam-modal-wrapper .contam-modal-details .pollution-sources-modal-wrapper {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 26px 0 0;
}

/* end Details CSS */

